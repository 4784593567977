import { useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";

import { useWalkdownManagement } from "../../hooks/useWalkdownManagement";
import { TAG_SCHEDULE_STATUS } from "../../constants/walkdown-management";
import { ENTER_KEY } from "../../constants/global";

export const AssignTagsSearchHeader = ({
  setRows,
  setIsScheduled,
  setNotScheduled,
  setDoesntExist,
}) => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { getEquipmentTags } = useWalkdownManagement();

  const handleSearchBtnClick = () => {
    if (search) {
      setLoading(true);
      getEquipmentTags(search)
        .then((response) => {
          if (response) {
            setRows(response);
            const { isSheduled, notScheduled, doesntExist } =
              groupTagsByScheduleStatus(response);
            setNotScheduled(notScheduled);
            setIsScheduled(isSheduled);
            setDoesntExist(doesntExist);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const groupTagsByScheduleStatus = (tags) => {
    const isSheduled = tags.filter(
      (item) => item.equipmentStatus === TAG_SCHEDULE_STATUS.IS_SCHEDULED
    );
    const notScheduled = tags.filter(
      (item) => item.equipmentStatus === TAG_SCHEDULE_STATUS.IS_NOT_SCHEDULED
    );
    const doesntExist = tags.filter(
      (item) => item.equipmentStatus === TAG_SCHEDULE_STATUS.DOES_NOT_EXIST
    );
    return {
      isSheduled,
      notScheduled,
      doesntExist,
    };
  };
  return (
    <>
      <TextField
        label="Search equipment"
        variant="outlined"
        className="w-full"
        onChange={handleSearchChange}
        inputProps={{
          "data-testid": "tag-search-input",
          onKeyDown: (event) =>
            event.code === ENTER_KEY ? handleSearchBtnClick() : null,
        }}
      />
      <Button
        sx={{ width: "96px", padding: "16px 20px" }}
        variant="contained"
        onClick={handleSearchBtnClick}
        data-testid="tag-search-btn"
      >
        {loading ? <CircularProgress size={20} color="info" /> : "Search"}
      </Button>
    </>
  );
};

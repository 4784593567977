import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Button, IconButton } from "@mui/material";

import {
  isScheduledEquipmentsUpdated,
  setIsScheduledEquipmentsUpdated,
  setInitialScheduleEquipmentStatuses,
  getUpdatedScheduleEquipmentStatuses,
  getInitialScheduleEquipmentStatuses,
  setUpdatedScheduleEquipmentStatuses,
} from "../../store/slices/walkdown-management/schedulesSlice";
import { isUserActionsEnabled } from "../../store/slices/global/userSlice";
import { useWalkdownManagement } from "../../hooks/useWalkdownManagement";

import { TransitionsModal } from "../common/Modal";
import { ImageInfo } from "../common/ImageInfo";
import { AssignTags } from "../common/AssignTags";
import { DeletionAgreement } from "../common/DeletionAgreement";
import { WalkdownScheduleEquipmentTable } from "./WalkdownScheduleEquipmentTable";

export const WalkdownScheduleEquipment = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserActionEnabled = useSelector(isUserActionsEnabled);
  const updatedStatuses = useSelector(getUpdatedScheduleEquipmentStatuses);
  const initialStatuses = useSelector(getInitialScheduleEquipmentStatuses);
  const isScheduleEquipmentsUpdated = useSelector(isScheduledEquipmentsUpdated);

  const [editMode, setEditMode] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [equipmentTags, setEquipmentTags] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const order = page * rowsPerPage;

  const [imageInfo, setImageInfo] = useState({});
  const [selectedImageInfo, setSelectedImageInfo] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [scheduleEquipmentId, setScheduleEquipmentId] = useState(null);
  const [imagesIdToBeDeleted, setImagesIdToBeDeleted] = useState([]);

  const [pending, setPending] = useState(false);
  const [deletionPending, setDeletionPending] = useState(false);
  const [isSchedulesLoading, setIsSchedulesLoading] = useState(true);
  const [isImagesLoading, setIsImagesLoading] = useState(false);

  const [openInfo, setOpenInfo] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAssignTagModal, setOpenAssignTagModal] = useState(false);
  const [openImageConfirmModal, setOpenImageConfirmModal] = useState(false);

  const {
    updateScheduleStatus,
    updateScheduleEquipment,
    deleteWalkdownEquipmentImages,
    deleteScheduleEquipment,
    getWalkdownEquipmentImages,
    getWalkdownScheduleEquipment,
  } = useWalkdownManagement();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsSchedulesLoading(true);
      getWalkdownScheduleEquipment(id, page, rowsPerPage)
        .then((response) => {
          if (isMounted && response) {
            setRows(response.result);
            setTotalRows(response.totalTags);
            setIsSchedulesLoading(false);
            setEquipmentTags(response.result.map((item) => item.equipmentTag));
            dispatch(setInitialScheduleEquipmentStatuses(response.result));
          }
        })
        .finally(() => {
          setIsSchedulesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [
    id,
    page,
    dispatch,
    rowsPerPage,
    isScheduleEquipmentsUpdated,
    getWalkdownScheduleEquipment,
  ]);

  useEffect(() => {
    if (equipmentTags.length) {
      setIsImagesLoading(true);
      const imageInfo = {};
      getWalkdownEquipmentImages(equipmentTags)
        .then((response) => {
          if (response) {
            response.forEach((item) => {
              imageInfo[item.equipmentTag] = {
                user: item.user,
                images: item.equipmentImageInfo,
              };
            });
            setImageInfo(imageInfo);
            setIsImagesLoading(false);
          }
        })
        .finally(() => {
          setIsImagesLoading(false);
        });
    }
  }, [equipmentTags, getWalkdownEquipmentImages]);

  const closeConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const agreeConfirmModal = () => {
    setDeletionPending(true);
    deleteScheduleEquipment(scheduleEquipmentId)
      .then((response) => {
        if (response) {
          setOpenConfirmModal(false);
          setScheduleEquipmentId(null);
          setDeletionPending(false);
          dispatch(setIsScheduledEquipmentsUpdated());
        }
      })
      .finally(() => {
        setDeletionPending(false);
      });
  };

  const closeImageConfirmModal = () => {
    setImagesIdToBeDeleted(
      imagesIdToBeDeleted.filter((id) => id !== selectedImageId)
    );
    setOpenImageConfirmModal(false);
  };

  const agreeImageConfirmModal = () => {
    setImagesIdToBeDeleted([...imagesIdToBeDeleted, selectedImageId]);
    setOpenImageConfirmModal(false);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleAssignTag = () => {
    setOpenAssignTagModal(true);
  };

  const closeAssignTagModal = () => {
    setOpenAssignTagModal(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setRows(initialStatuses);
    setImagesIdToBeDeleted([]);
    dispatch(setUpdatedScheduleEquipmentStatuses([]));
    setEditMode(false);
  };

  const handleSave = () => {
    if (updatedStatuses.length && imagesIdToBeDeleted.length) {
      updateAll();
    } else if (updatedStatuses.length) {
      updateOnlyStasuses();
    } else if (imagesIdToBeDeleted.length) {
      updateOnlyImages();
    } else {
      setEditMode(false);
      return;
    }
  };

  const updateAll = () => {
    setPending(true);
    updateScheduleEquipment(updatedStatuses, imagesIdToBeDeleted)
      .then((response) => {
        if (response) {
          setEditMode(false);
          dispatch(setIsScheduledEquipmentsUpdated());
        }
      })
      .finally(() => {
        setPending(false);
        setEditMode(false);
        dispatch(setUpdatedScheduleEquipmentStatuses([]));
      });
  };

  const updateOnlyStasuses = () => {
    setPending(true);
    updateScheduleStatus(updatedStatuses)
      .then((response) => {
        if (response) {
          setEditMode(false);
          dispatch(setIsScheduledEquipmentsUpdated());
        }
      })
      .finally(() => {
        setPending(false);
        setEditMode(false);
        dispatch(setUpdatedScheduleEquipmentStatuses([]));
      });
  };

  const updateOnlyImages = () => {
    setPending(true);
    deleteWalkdownEquipmentImages(imagesIdToBeDeleted)
      .then((response) => {
        if (response) {
          setEditMode(false);
          dispatch(setIsScheduledEquipmentsUpdated());
        }
      })
      .finally(() => {
        setPending(false);
        setEditMode(false);
        setImagesIdToBeDeleted([]);
      });
  };

  return (
    <div className="w-full">
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/walkdown-management")}
        data-testid="back-btn"
      >
        Back
      </Button>
      <div className="mt-8 w-full">
        <div className="mb-6">
          <div className="flex items-center justify-between h-[40px]">
            <h6
              data-testid="table-title"
              className="text-xl font-bold leading-6"
            >
              Walkdown schedule equipment
            </h6>
            {isUserActionEnabled && (
              <div className="flex items-center gap-6">
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleAssignTag}
                  disabled={pending}
                  data-testid="assign-tag-btn"
                >
                  Assign tag
                </Button>
                {!editMode ? (
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                    disabled={pending}
                    data-testid="edit-btn"
                  >
                    Edit
                  </Button>
                ) : (
                  <>
                    <IconButton
                      color="error"
                      onClick={handleCancel}
                      disabled={pending}
                      data-testid="cancel-btn"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={handleSave}
                      disabled={pending}
                      data-testid="save-btn"
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <WalkdownScheduleEquipmentTable
          page={page}
          setPage={setPage}
          rows={rows}
          imageInfo={imageInfo}
          setRows={setRows}
          editMode={editMode}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setSelectedImageInfo={setSelectedImageInfo}
          setSelectedImageId={setSelectedImageId}
          setScheduleEquipmentId={setScheduleEquipmentId}
          setOpenInfo={setOpenInfo}
          setOpenConfirmModal={setOpenConfirmModal}
          setOpenImageConfirmModal={setOpenImageConfirmModal}
          pending={pending}
          isImagesLoading={isImagesLoading}
          loading={isSchedulesLoading}
          imagesIdToBeDeleted={imagesIdToBeDeleted}
          isUserActionEnabled={isUserActionEnabled}
          order={order}
        />
        <TransitionsModal open={openInfo} handleClose={handleCloseInfo}>
          <ImageInfo selectedImageInfo={selectedImageInfo} />
        </TransitionsModal>
        <TransitionsModal
          open={openConfirmModal}
          handleClose={closeConfirmModal}
        >
          <DeletionAgreement
            title="Delete tag?"
            subtitle="You will not be able to restore it later."
            onCancel={closeConfirmModal}
            onConfirm={agreeConfirmModal}
            loading={deletionPending}
          />
        </TransitionsModal>
        <TransitionsModal
          open={openImageConfirmModal}
          handleClose={closeImageConfirmModal}
        >
          <DeletionAgreement
            title="Delete image?"
            subtitle="You will not be able to restore it later."
            onCancel={closeImageConfirmModal}
            onConfirm={agreeImageConfirmModal}
          />
        </TransitionsModal>
        <TransitionsModal
          open={openAssignTagModal}
          handleClose={closeAssignTagModal}
        >
          <AssignTags
            closeAssignTagModal={closeAssignTagModal}
            scheduleId={id}
          />
        </TransitionsModal>
      </div>
    </div>
  );
};

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";

import { isManufacturerListUpdated } from "../../../store/slices/work-with-data/manufacterSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";

import { GeneralJDEDetailsViewTable } from "./GeneralJDEDetailsViewTable";
import { GeneralJDECharacteristicsEditTable } from "./GeneralJDECharacteristicsEditTable";

import {
  CRITICALITY_OPTIONS,
  GENERAL_STATUS_OPTIONS,
} from "../../../constants/work-with-data";

export const GeneralJDECharacteristics = ({
  loading,
  rows,
  costCenterOptions,
  equipmentStatuses,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [plannerGroupOptions, setPlannerGroupOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const isManufacturerOptionsUpdated = useSelector(isManufacturerListUpdated);

  const { getPlannerGroupOptions, getManufacturerOptions } = useWorkWithData();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getPlannerGroupOptions().then((response) => {
        if (isMounted && response) {
          setPlannerGroupOptions(response);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getPlannerGroupOptions]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getManufacturerOptions().then((response) => {
        if (isMounted && response) {
          const manufaturers = response
            .filter((item) => item.manufacturerNameEng !== null)
            .map((item) => ({
              label: item.manufacturerNameEng,
              value: item.manufacturerNameEng,
              nameRus: item.manufacturerNameRus,
              status: item.manufacturerStatus,
              webLink: item.manufacturerWebSiteLink,
            }));

          setManufacturerOptions(manufaturers);
        }
      });
    };

    fetchRows();

    return () => {
      isMounted = false;
    };
  }, [getManufacturerOptions, isManufacturerOptionsUpdated]);

  const handleEditMode = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  return (
    <div className="flex flex-col w-[850px] min-h-[400px]">
      <Paper elevation={0} variant="outlined">
        <div>
          {!editMode && (
            <GeneralJDEDetailsViewTable
              rows={rows}
              loading={loading}
              editMode={editMode}
              handleEditMode={handleEditMode}
              tableTitle="General JDE characterictics"
              manufacturerOptions={manufacturerOptions}
            />
          )}
          {editMode && (
            <GeneralJDECharacteristicsEditTable
              rows={rows}
              equipmentStatuses={equipmentStatuses}
              criticalityOptions={CRITICALITY_OPTIONS}
              plannerGroupOptions={plannerGroupOptions}
              costCenterOptions={costCenterOptions}
              manufacturerOptions={manufacturerOptions}
              statusOptions={GENERAL_STATUS_OPTIONS}
              handleCancel={handleCancel}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
        </div>
      </Paper>
    </div>
  );
};

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { setIsScheduledEquipmentsUpdated } from "../../store/slices/walkdown-management/schedulesSlice";
import { useWalkdownManagement } from "../../hooks/useWalkdownManagement";

import { AssignTagsSearchHeader } from "./AssignTagsSearchHeader";
import { NotScheduledTagsTable } from "./NotScheduledTagsTable";

export const AssignTags = ({ closeAssignTagModal, scheduleId }) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [notScheduled, setNotScheduled] = useState([]);
  const [isScheduled, setIsScheduled] = useState([]);
  const [doesntExist, setDoesntExist] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const [pending, setPending] = useState(false);
  const { addEquipmentTags } = useWalkdownManagement();

  const localPayload = localStorage.getItem('assignTagPayload')
  const payload = localPayload && JSON.parse(localPayload);

  const handleAdd = () => {
    try {
      setPending(true);
      addEquipmentTags({
        scheduleID: Number(scheduleId),
        selectedEquipment: selectedTags,
        userID: payload?.userFk,
        walkDownStatus: payload?.scheduleStatus
      }).then(() => {
        closeAssignTagModal();
        dispatch(setIsScheduledEquipmentsUpdated());
        setPending(false);
      });
    } catch {
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="flex flex-col gap-4 w-[800px]">
      <div className="flex justify-between items-center">
        <h6 className="text-xl font-fira font-bold leading-6">Assign tag</h6>
        <IconButton color="default" onClick={closeAssignTagModal}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="flex items-center gap-4 justify-between w-full mb-2">
        <AssignTagsSearchHeader
          setRows={setRows}
          setIsScheduled={setIsScheduled}
          setNotScheduled={setNotScheduled}
          setDoesntExist={setDoesntExist}
        />
      </div>
      {rows.length ? (
        <>
          {notScheduled.length ? (
            <NotScheduledTagsTable
              setSelectedTags={setSelectedTags}
              pending={pending}
              selectedTags={selectedTags}
              notScheduled={notScheduled}
            />
          ) : null}
          <div className="m-[-16px] p-4 bg-white border-t border-gray-300">
            {isScheduled.length ? (
              <div className="max-h-[150px] overflow-hidden overflow-y-auto no-scrollbar mb-2">
                <Alert severity="warning" className="flex items-center">
                  {`Equipment with ${
                    isScheduled.length === 1 ? "tag" : "tags"
                  } ${isScheduled
                    .map((item) => item.equipmentTag)
                    .join(", ")} already passed through Walkdown.`}
                </Alert>
              </div>
            ) : null}
            {doesntExist.length ? (
              <div className="max-h-[150px] overflow-hidden overflow-y-auto no-scrollbar mb-2">
                <Alert severity="error" className="flex items-center">
                  {`Equipment with ${
                    doesntExist.length === 1 ? "tag" : "tags"
                  } ${doesntExist
                    .map((item) => item.equipmentTag)
                    .join(", ")} does not exist.`}
                </Alert>
              </div>
            ) : null}
            <div className="flex justify-between items-center mt-6">
              <div>
                {selectedTags.length ? (
                  <span>{`${selectedTags.length} ${
                    selectedTags.length === 1 ? "tag is " : "tags are"
                  } chosen`}</span>
                ) : null}
              </div>
              <div className="flex items-center justify-between gap-4">
                <Button
                  sx={{ width: "90px" }}
                  variant="outlined"
                  onClick={closeAssignTagModal}
                  disabled={pending}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ width: "90px" }}
                  variant="contained"
                  onClick={handleAdd}
                  disabled={pending || !selectedTags.length}
                >
                  {pending ? <CircularProgress size={20} /> : "Add"}
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

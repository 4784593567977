import {
  Badge,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ASSIGN_TAGS_TABLE_COLUMNS } from "../../constants/walkdown-management";
import { Fragment, useState } from "react";
import { FilterList as FilterListIcon } from "@mui/icons-material";

export const NotScheduledTagsTable = ({
  selectedTags,
  setSelectedTags,
  notScheduled,
  pending,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState("");

  const [filters, setFilters] = useState({
    equipmentTag: [],
    parentTag: [],
    areaCode: [],
    unitCode: [],
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filteredRows = notScheduled.filter((row) => {
    return Object.keys(filters).every((key) => {
      if (filters[key].length === 0) {
        return true;
      }
      return filters[key].includes(row[key].toString());
    });
  });

  const uniqueValues = (column) => {
    return [...new Set(notScheduled.map((row) => row[column]?.toString()))];
  };

  const filteredValues = uniqueValues(filterColumn).filter((value) =>
    value?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedTags([]);
    } else {
      setSelectedTags(filteredRows.map((item) => item.assetNumberPHK));
    }
    setIsAllSelected((isChecked) => !isChecked);
  };

  const handleSelectTag = (value) => {
    setSelectedTags((selectedTags) => {
      if (selectedTags.includes(value)) {
        return selectedTags.filter((item) => item !== value);
      } else {
        return [...selectedTags, value];
      }
    });
  };

  const handleFilterClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setFilterColumn(column);
    setSearchQuery("");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterColumn("");
    setSearchQuery("");
  };

  const handleCheckboxChange = (event, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (event.target.checked) {
        newFilters[filterColumn].push(value);
      } else {
        newFilters[filterColumn] = newFilters[filterColumn].filter(
          (item) => item !== value
        );
      }
      return newFilters;
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const resetFilter = () => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      newFilters[filterColumn] = [];
      return newFilters;
    });
  };

  return (
    <Paper
      sx={{ width: "100%", mb: 2, p: "1px" }}
      elevation={0}
      variant="outlined"
    >
      <TableContainer sx={{ height: 300 }}>
        <Table stickyHeader aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {ASSIGN_TAGS_TABLE_COLUMNS.map((col) => (
                <TableCell width={col.width} key={col.id}>
                  {col.id === "checkbox" ? (
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={isAllSelected}
                      disabled={pending}
                    />
                  ) : (
                    <>
                      {col.label}
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={filters[col.id]?.length === 0}
                      >
                        <IconButton
                          onClick={(e) => handleFilterClick(e, col.id)}
                        >
                          <FilterListIcon />
                        </IconButton>
                      </Badge>
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!filteredRows.length && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="flex items-center justify-center w-full h-full min-h-[190px]">
                    <Typography fontSize={14} className="text-black/[0.5]">
                      Data not found
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableBody>
            {filteredRows.map((row) => {
              return (
                <TableRow key={row.equipmentTag} tabIndex={-1}>
                  {ASSIGN_TAGS_TABLE_COLUMNS.map((col) => {
                    return (
                      <Fragment key={col.id}>
                        {col.id === "checkbox" ? (
                          <TableCell width={col.width} onClick={() => {}}>
                            <Checkbox
                              checked={selectedTags.includes(
                                row.assetNumberPHK
                              )}
                              onChange={() =>
                                handleSelectTag(row.assetNumberPHK)
                              }
                              disabled={pending}
                            />
                          </TableCell>
                        ) : (
                          <TableCell width={col.width}>
                            {col.id === "label" ? (
                              <span className="text-sm font-roboto font-bold leading-4">
                                {row[col.id]}
                              </span>
                            ) : (
                              row[col.id]
                            )}
                          </TableCell>
                        )}
                      </Fragment>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          slotProps={{
            paper: {
              style: { maxHeight: 350, width: "300px" },
            },
          }}
        >
          <div className="sticky top-0 bg-white p-1 z-10">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ m: 2, width: "90%" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {filters[filterColumn]?.length ? (
              <div className="mr-4 flex justify-end w-full">
                <Button onClick={resetFilter} className="flex-end" size="small">
                  Reset
                </Button>
              </div>
            ) : null}
          </div>
          <List>
            {filteredValues.map((value) => (
              <ListItem key={value}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filters[filterColumn].includes(value)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": value }}
                    onChange={(e) => handleCheckboxChange(e, value)}
                  />
                </ListItemIcon>
                <ListItemText id={value} primary={value} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </TableContainer>
    </Paper>
  );
};

import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import {
  setIsCharacteristicsUpdated,
  setIsSpecificCharsUpdated,
} from "../../../store/slices/work-with-data/characteristicsSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";
import { debounce, getUpdatedJDEState } from "../../../utils";

import { TransitionsModal } from "../../common/Modal";
import { AddNewManufacturer } from "../../common/AddNewManufacturer";

import { EQUIPMENT_GENERAL_CHAR_NAME } from "../../../constants/work-with-data";

export const GeneralJDECharacteristicsEditTable = ({
  rows,
  criticalityOptions,
  plannerGroupOptions,
  costCenterOptions,
  manufacturerOptions,
  statusOptions,
  equipmentStatuses,
  handleCancel,
  editMode,
  setEditMode,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(rows);
  const [isPending, setIsPending] = useState(false);
  const [openManufacturerModal, setOpenManufacturerModal] = useState(false);
  const { updateJdeEquipmentTagCharacteristics } = useWorkWithData();

  const getOptionsByCharName = (charName) => {
    switch (charName) {
      case EQUIPMENT_GENERAL_CHAR_NAME.AC_RANKING:
        return criticalityOptions;
      case EQUIPMENT_GENERAL_CHAR_NAME.PLANNER_GROUP:
        return plannerGroupOptions;
      case EQUIPMENT_GENERAL_CHAR_NAME.COST_CENTER:
        return costCenterOptions;
      case EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER:
        return manufacturerOptions;
      case EQUIPMENT_GENERAL_CHAR_NAME.STATUS:
        return statusOptions;
      default:
        return [];
    }
  };

  const handleAutocompleteChange = (_event, option, characteristicName) => {
    if (option) {
      const newState = state.map((item) =>
        item.jdeCharacteristicName === characteristicName
          ? { ...item, characteristicValue: option.value }
          : item
      );
      setState(newState);
    }
  };

  const handleTextFieldChange = debounce((event, characteristicName) => {
    const value = event.target.value;
    const newState = state.map((item) =>
      item.jdeCharacteristicName === characteristicName
        ? { ...item, characteristicValue: value }
        : item
    );
    setState(newState);
  }, 500);

  const handleDatePickerChange = (date, characteristicName) => {
    const value = dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    const newState = state.map((item) =>
      item.jdeCharacteristicName === characteristicName
        ? { ...item, characteristicValue: value }
        : item
    );
    setState(newState);
  };

  const handleAddManufacturer = () => {
    setOpenManufacturerModal(true);
  };

  const closeManufacturerModal = () => {
    setOpenManufacturerModal(false);
  };

  const handleSave = () => {
    const updated = getUpdatedJDEState(rows, state, equipmentStatuses);

    if (!updated.length) {
      setEditMode(false);
      return;
    }

    setIsPending(true);
    updateJdeEquipmentTagCharacteristics(updated)
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            setEditMode(false);
            dispatch(setIsCharacteristicsUpdated());
            dispatch(setIsSpecificCharsUpdated());
            setIsPending(false);
          }
        }
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  const handleAllStatusChange = (_event, option) => {
    if (option) {
      const newState = state.map((item) => ({
        ...item,
        characteristicStatusName: option.label,
      }));
      setState(newState);
    }
  };

  const handleRowStatusChange = (_event, option, characteristicName) => {
    if (option) {
      const newState = state.map((item) =>
        item.characteristicStatusName !== option.label &&
        item.jdeCharacteristicName === characteristicName
          ? { ...item, characteristicStatusName: option.label }
          : item
      );
      setState(newState);
    }
  };

  const renderTableCell = (row) => {
    const { jdeCharacteristicName, jdeCharacteristicDescription, characteristicValue } = row;
    switch (jdeCharacteristicName) {
      case EQUIPMENT_GENERAL_CHAR_NAME.AC_RANKING:
      case EQUIPMENT_GENERAL_CHAR_NAME.PLANNER_GROUP:
      case EQUIPMENT_GENERAL_CHAR_NAME.COST_CENTER:
      case EQUIPMENT_GENERAL_CHAR_NAME.STATUS:
        return (
          <TableCell align="left" width={550} sx={{ paddingY: "12px" }}>
            <Autocomplete
              size="small"
              value={characteristicValue}
              disablePortal
              options={getOptionsByCharName(jdeCharacteristicName)}
              renderInput={(params) => (
                <TextField {...params} label={jdeCharacteristicDescription} />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange(event, value, jdeCharacteristicName)
              }
              disabled={isPending}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
            />
          </TableCell>
        );
      case EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER:
        return (
          <TableCell
            align="left"
            width={550}
            sx={{
              paddingY: "12px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Autocomplete
              size="small"
              sx={{ width: 400 }}
              value={characteristicValue}
              disablePortal
              options={getOptionsByCharName(jdeCharacteristicName)}
              renderInput={(params) => (
                <TextField {...params} label={jdeCharacteristicDescription} />
              )}
              onChange={(event, value) =>
                handleAutocompleteChange(event, value, jdeCharacteristicName)
              }
              disabled={isPending}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
            />
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={handleAddManufacturer}
              disabled={isPending}
              data-testid="add-new-btn"
            >
              Add new
            </Button>
          </TableCell>
        );
      case EQUIPMENT_GENERAL_CHAR_NAME.ACQN_VALUE_DATE:
      case EQUIPMENT_GENERAL_CHAR_NAME.START_UP_DATE:
        return (
          <TableCell align="left" width={550} sx={{ paddingY: "12px" }}>
            <DatePicker
              format="DD/MM/YYYY"
              sx={{ width: "100%" }}
              label={jdeCharacteristicDescription}
              value={dayjs(characteristicValue)}
              slots={{
                textField: (params) => <TextField size="small" {...params} />,
              }}
              onChange={(value) =>
                handleDatePickerChange(value, jdeCharacteristicName)
              }
              disabled={isPending}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell align="left" width={550} sx={{ paddingY: "12px" }}>
            <TextField
              className="w-full"
              size="small"
              label={jdeCharacteristicDescription}
              variant="outlined"
              defaultValue={characteristicValue}
              disabled={isPending}
              onChange={(event) =>
                handleTextFieldChange(event, jdeCharacteristicName)
              }
            />
          </TableCell>
        );
    }
  };

  return (
    <>
      <div className="flex items-center justify-between w-full px-4 pt-4">
        <h6 className="text-base not-italic font-bold leading-5">General</h6>
        <div className="flex items-center gap-4">
          {editMode && (
            <>
              <IconButton
                disabled={isPending}
                color="error"
                onClick={handleCancel}
                data-testid="cancel-btn"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <IconButton
                disabled={isPending}
                color="success"
                onClick={handleSave}
                data-testid="save-btn"
              >
                {isPending ? (
                  <CircularProgress size={20} />
                ) : (
                  <CheckIcon fontSize="small" />
                )}
              </IconButton>
            </>
          )}
        </div>
      </div>
      <TableContainer>
        <Table sx={{ width: 840 }} size="small">
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={2}
                className="w-full"
                sx={{ paddingY: "12px" }}
              >
                <Autocomplete
                  size="small"
                  disablePortal
                  options={equipmentStatuses}
                  renderInput={(params) => (
                    <TextField {...params} label="Status for all" />
                  )}
                  onChange={handleAllStatusChange}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  disabled={isPending}
                />
              </TableCell>
            </TableRow>
            {state.map((row) => (
              <TableRow
                key={row.jdeCharacteristicName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {renderTableCell(row)}
                <TableCell
                  align="left"
                  width={330}
                  sx={{ paddingY: "12px", paddingLeft: 0 }}
                >
                  <Autocomplete
                    size="small"
                    value={row.characteristicStatusName}
                    disablePortal
                    options={equipmentStatuses}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={(event, value) =>
                      handleRowStatusChange(
                        event,
                        value,
                        row.jdeCharacteristicName
                      )
                    }
                    disabled={isPending}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    disableClearable
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TransitionsModal
        open={openManufacturerModal}
        handleClose={closeManufacturerModal}
      >
        <AddNewManufacturer
          closeManufacturerModal={closeManufacturerModal}
          manufacturerOptions={manufacturerOptions}
        />
      </TransitionsModal>
    </>
  );
};

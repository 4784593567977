import { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RefreshIcon from "@mui/icons-material/Refresh";

import { Drawer } from "@mui/material";

import { getColumns } from "../../store/slices/work-with-data/columnsSlice";
import {
  clearAllFilters,
  getFilterParams,
  getTableFilter,
  setFilterParams,
  setTableFilter,
  getCurrentPage,
} from "../../store/slices/work-with-data/filterSlice";
import { useWorkWithData } from "../../hooks/useWorkWithData";
import { getFormattedCategories, downloadWorkWithData } from "../../utils";

import { HeaderWithActions } from "../common/HeaderWithActions";
import { CategoriesDrawer } from "../common/CategoriesDrawer";
import { WorkWithDataFilters } from "./WorkWithDataFilters";
import { WorkWithDataTable } from "./WorkWithDataTable";
import { FILENAME } from "../../constants/work-with-data";
import { FILE_FORMAT } from "../../constants/global";

export const WorkWithData = () => {
  const dispatch = useDispatch();
  const columns = useSelector(getColumns);
  const filterParams = useSelector(getFilterParams);
  const tableFilter = useSelector(getTableFilter);
  const [categories, setCategoriesList] = useState([]);
  const [openCategories, setOpenCategories] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const [rows, setRows] = useState([]);
  const savedRowsPerPage = sessionStorage.getItem("wwdRowsPerPage");
  const [rowsPerPage, setRowsPerPage] = useState(
    savedRowsPerPage ? Number(savedRowsPerPage) : 25
  );
  const [totalRows, setTotalRows] = useState(0);
  const currentPage = useSelector(getCurrentPage);

  const [loading, setLoading] = useState(false);

  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isCSVLoading, setIsCSVLoading] = useState(false);

  const { getCategoriesList, getElements } = useWorkWithData();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setLoading(true);
      getElements({
        ...filterParams,
        AreaCode: filterParams.AreaCode.value ?? undefined,
        UnitCode: filterParams.UnitCode.value ?? undefined,
        StartFrom: currentPage,
        FetchRecord: rowsPerPage,
      })
        .then((response) => {
          if (isMounted && response) {
            setLoading(false);
            setRows(response.items);
            setTotalRows(response.count);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchRows();
  }, [currentPage, filterParams, getElements, rowsPerPage]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getCategoriesList().then((response) => {
        if (isMounted && response) {
          const categories = getFormattedCategories(response);
          setCategoriesList(categories);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [dispatch, getCategoriesList]);

  const toggleCategoriesDrawer = (newOpen) => () => {
    setOpenCategories(newOpen);
  };

  const toggleFiltersDrawer = (newOpen) => () => {
    setOpenFilters(newOpen);
  };

  const handleRefresh = useCallback(() => {
    dispatch(setFilterParams({ ...filterParams }));
    dispatch(setTableFilter({ ...tableFilter }));
  }, [dispatch, filterParams, tableFilter]);

  const handleDownloadExcel = useCallback(() => {
    setIsExcelLoading(true);
    getElements({
      ...filterParams,
      AreaCode: filterParams.AreaCode.value ?? undefined,
      UnitCode: filterParams.UnitCode.value ?? undefined,
      StartFrom: 0,
      FetchRecord: totalRows,
    })
      .then((response) => {
        if (response) {
          downloadWorkWithData(
            response.items,
            columns,
            FILENAME,
            FILE_FORMAT.EXCEL
          );
          setIsExcelLoading(false);
        }
      })
      .finally(() => {
        setIsExcelLoading(false);
      });
  }, [columns, getElements, totalRows, filterParams]);

  const handleDownloadCSV = useCallback(() => {
    setIsCSVLoading(true);
    getElements({
      ...filterParams,
      AreaCode: filterParams.AreaCode.value ?? undefined,
      UnitCode: filterParams.UnitCode.value ?? undefined,
      StartFrom: 0,
      FetchRecord: totalRows,
    })
      .then((response) => {
        if (response) {
          downloadWorkWithData(
            response.items,
            columns,
            FILENAME,
            FILE_FORMAT.CSV
          );
          setIsCSVLoading(false);
        }
      })
      .finally(() => {
        setIsCSVLoading(false);
      });
  }, [columns, getElements, totalRows, filterParams]);

  const tableActions = useMemo(
    () => [
      {
        label: "Filters",
        onClick: toggleFiltersDrawer(true),
        Icon: FilterListIcon,
        type: "button",
        disabled: false,
        isVisible: true,
        id: "filters",
      },
      {
        label: "Clear all",
        onClick: () => dispatch(clearAllFilters()),
        Icon: DeleteForeverIcon,
        type: "button",
        disabled: false,
        isVisible: true,
        id: "clearAll",
      },
      {
        label: "Refresh",
        onClick: handleRefresh,
        Icon: RefreshIcon,
        type: "button",
        disabled: false,
        isVisible: true,
        id: "refresh",
      },
      {
        label: "Download",
        downloadExcel: handleDownloadExcel,
        downloadCSV: handleDownloadCSV,
        isExcelLoading,
        isCSVLoading,
        type: "menu",
        disabled: isExcelLoading || isCSVLoading,
        isVisible: true,
        id: "download",
      },
    ],
    [
      dispatch,
      handleDownloadCSV,
      handleDownloadExcel,
      handleRefresh,
      isCSVLoading,
      isExcelLoading,
    ]
  );

  return (
    <div className="flex flex-col gap-4 w-full min-w-[1200px]">
      <HeaderWithActions title="Work with data" actions={tableActions} />
      <WorkWithDataTable
        rows={rows}
        loading={loading}
        columns={columns}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        setRowsPerPage={setRowsPerPage}
        toggleCategoriesDrawer={toggleCategoriesDrawer}
      />
      <Drawer
        open={openCategories}
        onClose={toggleCategoriesDrawer(false)}
        anchor="right"
      >
        <CategoriesDrawer
          toggleCategoriesDrawer={toggleCategoriesDrawer}
          categories={categories}
        />
      </Drawer>
      <Drawer
        open={openFilters}
        onClose={toggleFiltersDrawer(false)}
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
      >
        <WorkWithDataFilters toggleFiltersDrawer={toggleFiltersDrawer} />
      </Drawer>
    </div>
  );
};

export const SCHEDULES_TABLE_COLUMNS = [
  {
    id: "walkdownScheduleMasterPk",
    label: "Schedule ID",
    width: 120,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "plannedStartDate",
    label: "Planned start date",
    width: 250,
    isVisible: true,
    order: 2,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "plannedCompletionDate",
    label: "Planned end date",
    width: 250,
    isVisible: true,
    order: 3,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "scheduleModifiedDate",
    label: "Modified date",
    width: 250,
    isVisible: true,
    order: 4,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "user",
    label: "User",
    width: 250,
    isVisible: true,
    order: 5,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "actions",
    label: "Actions",
    width: 200,
    isVisible: true,
    order: 6,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
];

export const USERS_TABLE_COLUMNS = [
  {
    id: "userPk",
    label: "User ID",
    width: 100,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "firstName",
    label: "Name",
    width: 250,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "lastName",
    label: "Surname",
    width: 250,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "email",
    label: "E-mail",
    width: 500,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "isAdmin",
    label: "Admin role",
    width: 150,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "center",
  },
  {
    id: "actions",
    label: "Actions",
    width: 120,
    isVisible: true,
    order: 6,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
];

export const WALKDOWN_SCHEDULE_TABLE_COLUMNS = [
  {
    id: "index",
    label: "#",
    width: 50,
    isVisible: true,
    order: 1,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "equipmentTag",
    label: "Tag",
    width: 200,
    isVisible: true,
    order: 2,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "pictures",
    label: "Pictures",
    width: 400,
    isVisible: true,
    order: 3,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "equipmentWalkdownStatus",
    label: "Status",
    width: 200,
    isVisible: true,
    order: 4,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "areaName",
    label: "Area",
    width: 120,
    isVisible: true,
    order: 5,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "unitName",
    label: "Unit",
    width: 140,
    isVisible: true,
    order: 6,
    isChecked: true,
    isDisabled: false,
    align: "left",
  },
  {
    id: "actions",
    label: "Actions",
    width: 80,
    isVisible: true,
    order: 7,
    isChecked: true,
    isDisabled: false,
    align: "center",
  },
];

export const ASSIGN_TAGS_TABLE_COLUMNS = [
  {
    id: "equipmentTag",
    label: "Equipment tag",
    width: 150,
  },
  {
    id: "parentTag",
    label: "Parent tag",
    width: 100,
  },
  {
    id: "areaCode",
    label: "Area code",
    width: 100,
  },
  {
    id: "unitCode",
    label: "Unit code",
    width: 100,
  },
  {
    id: "checkbox",
    label: "",
    width: 40,
  },
];

export const TAG_SCHEDULE_STATUS = {
  IS_NOT_SCHEDULED: "tag is not scheduled",
  IS_SCHEDULED: "tag is scheduled",
  DOES_NOT_EXIST: "tag does not exist",
};

export const SCHEDULE_EQUIPMENT_STATUS = {
  PLANNED: "planned",
  SUBMITTED: "submitted",
  COMPLETED: "completed",
};

export const SCHEDULE_STATUS_OPTIONS = [
  {
    value: "planned",
    label: "planned",
  },
  {
    value: "submitted",
    label: "submitted",
  },
  {
    value: "completed",
    label: "completed",
  },
];

import { useState } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Paper, IconButton } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";

import { setIsWalkdownPicturesUpdated } from "../../../store/slices/work-with-data/characteristicsSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";

import { NotFound } from "../../common/NotFound";
import { ImageInfo } from "../../common/ImageInfo";
import { TransitionsModal } from "../../common/Modal";
import { ImagesGalleryList } from "../../common/ImagesGalleryList";
import { DeletionAgreement } from "../../common/DeletionAgreement";
import { DetailsActionsWithHeader } from "../../common/DetailsActionsWithHeader";

import NotFoundImagesIcon from "../../../assets/no-images.svg";

export const EquipmentWalkdownPictures = ({ images = [], user, loading }) => {
  const dispatch = useDispatch();
  const [selectedImageInfo, setSelectedImageInfo] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [imagesIdToBeDeleted, setImagesIdToBeDeleted] = useState([]);

  const { deleteWalkdownPictures } = useWorkWithData();

  const handleEditMode = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setImagesIdToBeDeleted([]);
    setEditMode(false);
  };

  const handleSave = () => {
    if (!imagesIdToBeDeleted.length) {
      setEditMode(false);
    } else {
      setPending(true);
      deleteWalkdownPictures(imagesIdToBeDeleted)
        .then((response) => {
          if (response) {
            setPending(false);
            setEditMode(false);
            dispatch(setIsWalkdownPicturesUpdated());
          }
        })
        .finally(() => {
          setPending(false);
          setEditMode(false);
        });
    }
  };

  const handleDelete = (e, imageId) => {
    e.stopPropagation();
    setSelectedImageId(imageId);
    setOpenConfirmModal(true);
  };

  const handleOpenInfo = (event, image) => {
    event.stopPropagation();
    setSelectedImageInfo({ image, user });
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const renderMask = (image) => {
    return (
      <div className="flex flex-col justify-end items-center gap-4 h-full w-full pb-4">
        {editMode && (
          <IconButton
            sx={{
              color: "#fff",
              ":hover": { backgroundColor: "#F44336" },
            }}
            onClick={(e) => handleDelete(e, image.equipmentImagePk)}
            disabled={pending}
          >
            <DeleteOutlineOutlined />
          </IconButton>
        )}
        <div className="bg-black/[0.5] flex items-center w-full justify-between px-2">
          <span>{image.imageDescription}</span>
          <IconButton
            onClick={(event) => handleOpenInfo(event, image)}
            disabled={pending}
          >
            <span className="text-white text-sm rounded-md border-2 border-white hover:bg-white/[0.3]  py-1 px-2">
              More...
            </span>
          </IconButton>
        </div>
      </div>
    );
  };

  const closeConfirmModal = () => {
    setImagesIdToBeDeleted(
      imagesIdToBeDeleted.filter((id) => id !== selectedImageId)
    );
    setOpenConfirmModal(false);
  };

  const agreeConfirmModal = () => {
    setImagesIdToBeDeleted([...imagesIdToBeDeleted, selectedImageId]);
    setOpenConfirmModal(false);
  };

  return (
    <>
      <Paper
        elevation={0}
        variant="outlined"
        className="min-h-[400px] min-w-[850px] max-w-[1200px] w-fit"
      >
        <DetailsActionsWithHeader
          header="Walkdown pictures"
          handleCancel={handleCancel}
          handleEditMode={handleEditMode}
          handleSave={handleSave}
          editMode={editMode}
          actionMode={Boolean(images.length)}
          loading={loading}
          isActionsDisabled={pending}
        />
        <div className="flex flex-auto flex-wrap gap-4 p-4">
          {loading ? (
            <div className="flex w-full flex-col gap-2 justify-center items-center min-h-[300px]">
              <CircularProgress />
            </div>
          ) : (
            <>
              {!images.length && (
                <NotFound
                  imageUrl={NotFoundImagesIcon}
                  text="No pictures found"
                />
              )}
              <ImagesGalleryList
                width={180}
                height={180}
                images={images.filter(
                  (image) =>
                    !imagesIdToBeDeleted.includes(image.equipmentImagePk)
                )}
                renderMask={renderMask}
              />
            </>
          )}
        </div>
      </Paper>
      <TransitionsModal open={openInfo} handleClose={handleCloseInfo}>
        <ImageInfo selectedImageInfo={selectedImageInfo} />
      </TransitionsModal>
      <TransitionsModal open={openConfirmModal} handleClose={closeConfirmModal}>
        <DeletionAgreement
          title="Delete image?"
          subtitle="You will not be able to restore it later."
          onCancel={closeConfirmModal}
          onConfirm={agreeConfirmModal}
        />
      </TransitionsModal>
    </>
  );
};

import { useSelector } from "react-redux";

import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DateRangeIcon from "@mui/icons-material/DateRange";

import {
  displayCriticalily,
  getFormattedDate,
  getStatusSeverity,
} from "../../../utils";
import { isUserActionsEnabled } from "../../../store/slices/global/userSlice";
import {
  EQUIPMENT_GENERAL_CHAR_NAME,
  MANUFACTURER_STATUS,
} from "../../../constants/work-with-data";

import { StatusAlert } from "../../common/StatusAlert";
import { TableLoader } from "../../common/TableLoader";
import { NoDataFoundInTable } from "../../common/NoDataFoundInTable";

export const GeneralJDEDetailsViewTable = ({
  rows,
  loading,
  handleEditMode,
  editMode,
  tableTitle,
  manufacturerOptions,
}) => {
  const isUserActionEnabled = useSelector(isUserActionsEnabled);

  const displayTableCell = (row) => {
    const { jdeCharacteristicName, characteristicValue } = row;

    let manufacturerInfo;
    if (jdeCharacteristicName === EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER) {
      manufacturerInfo = manufacturerOptions.find(
        ({ value }) => value === characteristicValue
      );
    }

    switch (jdeCharacteristicName) {
      case EQUIPMENT_GENERAL_CHAR_NAME.ACQN_VALUE_DATE:
      case EQUIPMENT_GENERAL_CHAR_NAME.START_UP_DATE:
        return (
          <div className="flex items-center gap-2">
            <DateRangeIcon className="text-black/[0.54]" />
            <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
              {getFormattedDate(characteristicValue)}
            </span>
          </div>
        );
      case EQUIPMENT_GENERAL_CHAR_NAME.AC_RANKING:
        return displayCriticalily(characteristicValue);
      default:
        return jdeCharacteristicName ===
          EQUIPMENT_GENERAL_CHAR_NAME.MANUFACTURER &&
          manufacturerInfo?.status === MANUFACTURER_STATUS.NEW ? (
          <div className="flex items-center gap-2 cursor-pointer bg-[#C1E1C1]/[0.5] rounded p-1">
            <Tooltip placement="top" title="Manufacturer is not approved yet">
              <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
                {characteristicValue}
              </span>
            </Tooltip>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
              {characteristicValue}
            </span>
          </div>
        );
    }
  };

  return (
    <>
      <div className="flex items-center justify-between w-full px-4 pt-4">
        <h6
          data-testid="table-title"
          className="text-base not-italic font-bold leading-5"
        >
          {tableTitle}
        </h6>
        {/* {isUserActionEnabled && rows.length ? (
          <div className="flex items-center gap-4">
            {!editMode && !loading && (
              <IconButton
                data-testid="edit-btn"
                color="primary"
                onClick={handleEditMode}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ) : null} */}
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="equipment general view table">
          {loading ? (
            <TableLoader width={840} />
          ) : (
            <>
              {!rows.length && !loading && (
                <NoDataFoundInTable label="No details" colSpan={3} />
              )}
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.jdeEquipmentTagCharPhk}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      width={250}
                      component="th"
                      scope="row"
                    >
                      <div className="flex flex-col">
                        <span className="text-sm leading-4 text-black/[0.54] font-roboto">
                          {row.jdeCharacteristicDescription}
                        </span>
                        <span className="text-[10px] text-black/[0.54]">
                          [{row.jdeCharacteristicName?.toLowerCase()}]
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" width={340}>
                      <div className="flex items-center gap-2">
                        <span className="text-sm leading-4 text-black/[0.87] font-roboto ">
                          {displayTableCell(row)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left" width={250}>
                      <StatusAlert
                        value={row.characteristicStatusName}
                        severity={getStatusSeverity(
                          row.characteristicStatusName
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export const AppLogo = () => {
  return (
    <div className="flex items-center gap-[16px]">
      <img
        className="flex w-12 h-12 justify-center items-center"
        src={"/TCO.png"}
        alt="app logo"
      />
      <h6 className="text-white text-xl not-italic font-bold leading-6">
        TCO Digital Core Asset Management
      </h6>
    </div>
  );
};

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import RuleFolderOutlinedIcon from "@mui/icons-material/RuleFolderOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";

import { capitalizeLetter, getFormattedDate } from "../../utils";
import { StatusAlert } from "./StatusAlert";

export const ImageInfo = ({ selectedImageInfo }) => {
  const image = selectedImageInfo?.image;
  const user = selectedImageInfo?.user;
  return (
    <List
      dense
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        padding: 0,
        overflow: "auto",
        maxHeight: 550,
        maxWidth: 400,
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ViewInArOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="View"
          secondary={
            image?.imageDescription || (
              <StatusAlert
                component="span"
                value="View is not set"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <RuleFolderOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Status"
          secondary={
            image?.equipmentImageStatus ? (
              <StatusAlert
                component="span"
                value={capitalizeLetter(image?.equipmentImageStatus)}
                severity={
                  image?.equipmentImageStatus === "not verified"
                    ? "error"
                    : "success"
                }
              />
            ) : (
              <StatusAlert
                component="span"
                value="Status not defined"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <DateRangeOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Created date"
          secondary={getFormattedDate(image?.createdDate)}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <VerticalSplitOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Model"
          secondary={
            image?.equipmentModel || (
              <StatusAlert
                component="span"
                value="Model not found"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WarehouseOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Manufacturer"
          secondary={
            image?.equipmentManufacturer || (
              <StatusAlert
                component="span"
                value="Unknown manufacturer"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <NumbersIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Serial number"
          secondary={
            image?.equipmentSerialNo || (
              <StatusAlert
                component="span"
                value="Unknown serial number"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BuildCircleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Operational status"
          secondary={
            image?.equipmentOperationalStatus || (
              <StatusAlert
                component="span"
                value="Unknown operational status"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EventAvailableOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Completion date"
          secondary={getFormattedDate(image?.completedDate)}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <BadgeOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="User"
          secondary={
            user ? (
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
            ) : (
              <StatusAlert
                component="span"
                value="User is unknown"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <MessageOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Comment"
          secondary={
            image?.comment || (
              <StatusAlert
                component="span"
                value="No comments"
                severity="warning"
              />
            )
          }
        />
      </ListItem>
    </List>
  );
};

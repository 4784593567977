import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userRole: null,
    userRoleOptions: [],
    isUserActionsEnabled: true,
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setUserRoleOptions: (state, action) => {
      state.userRoleOptions = action.payload;
    },
    setIsUserActionsEnabled: (state, action) => {
      state.isUserActionsEnabled = action.payload;
    },
  },
});

export const {
  setUserRole,
  setUserRoleOptions,
  setUserRoleLoading,
  setIsUserActionsEnabled,
} = userSlice.actions;
export const selectUserRole = (state) => state.user.userRole;
export const getUserRoleOptions = (state) => state.user.userRoleOptions;
export const isUserActionsEnabled = (state) => state.user.isUserActionsEnabled;
export default userSlice.reducer;

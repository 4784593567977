import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Button, ButtonGroup } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { selectUserRole } from "../../../store/slices/global/userSlice";
import {
  isCharacteristicsUpdated,
  isSpecificCharsUpdated,
  isWalkdownPicturesUpdated,
  selectedEquipmentTagId,
} from "../../../store/slices/work-with-data/characteristicsSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";
import { getStatusesByUserRole } from "../../../utils";

import {
  GeneralJDECharacteristics,
  EquipmentDocuments,
  EquipmentWalkdownPictures,
} from ".";
// import { GeneralMDSCharacteristics } from "./GeneralMDSCharacteristics";
// import { ClassSpecificMDSCharacteristics } from "./ClassSpecificMDSCharacteristics";
import { EQUIPMENT_GENERAL_CHAR_NAME } from "../../../constants/work-with-data";

export const WorkWithDataDetails = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const equipmentTagId = useSelector(selectedEquipmentTagId);
  const isGeneralEquipmentsUpdated = useSelector(isCharacteristicsUpdated);
  const isSpecificEquipmentsUpdated = useSelector(isSpecificCharsUpdated);
  const isEquipmentPicturesUpdated = useSelector(isWalkdownPicturesUpdated);
  const [activeButton, setActiveButton] = useState("generalJDE");

  const [generalJDEChars, setGeneralJDEChars] = useState([]);
  const [isGeneralJDECharsLoading, setIsGeneralJDECharsLoading] =
    useState(true);

  // const [generalMDSChars, setGeneralMDSChars] = useState([]);
  // const [isGeneralMDSCharsLoading, setIsGeneralMDSCharsLoading] =
  //   useState(true);

  // const [specificMDSChars, setSpecificMDSChars] = useState([]);
  // const [isSpecificMDSCharsLoading, setIsSpecificMDSCharsLoading] =
  //   useState(true);

  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [equipmentStatuses, setEquipmentStatuses] = useState([]);

  const [imagesInfo, setImagesInfo] = useState(null);

  const [isImagesLoading, setIsImagesLoading] = useState(true);

  const {
    getGeneralJDECharacterictics,
    // getMDSCharacterictics,
    getWalkdownPictures,
    getCostCenterOptions,
    getEquipmentStatuses,
  } = useWorkWithData();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsGeneralJDECharsLoading(true);
      getGeneralJDECharacterictics(id)
        .then((response) => {
          if (isMounted && response) {
            const formattedResponse = response
              .sort((a, b) =>
                a.jdeCharacteristicDescription > b.jdeCharacteristicDescription
                  ? 1
                  : -1
              )
              .filter(
                (item) =>
                  EQUIPMENT_GENERAL_CHAR_NAME[item.jdeCharacteristicName]
              );
            setGeneralJDEChars(formattedResponse);
            setIsGeneralJDECharsLoading(false);
          }
        })
        .finally(() => {
          setIsGeneralJDECharsLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [
    id,
    getGeneralJDECharacterictics,
    dispatch,
    isGeneralEquipmentsUpdated,
    isSpecificEquipmentsUpdated,
  ]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const fetchRows = () => {
  //     setIsGeneralMDSCharsLoading(true);
  //     getMDSCharacterictics(id, true)
  //       .then((response) => {
  //         if (isMounted && response) {
  //           const sorted = response.sort((a, b) =>
  //             a.mdsCharacteristicDescription > b.mdsCharacteristicDescription
  //               ? 1
  //               : -1
  //           );
  //           setGeneralMDSChars(sorted);
  //           setIsGeneralMDSCharsLoading(false);
  //         }
  //       })
  //       .finally(() => {
  //         setIsGeneralMDSCharsLoading(false);
  //       });
  //   };

  //   fetchRows();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   id,
  //   getMDSCharacterictics,
  //   dispatch,
  //   isGeneralEquipmentsUpdated,
  //   isSpecificEquipmentsUpdated,
  // ]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const fetchRows = () => {
  //     setIsSpecificMDSCharsLoading(true);
  //     getMDSCharacterictics(id, false)
  //       .then((response) => {
  //         if (isMounted && response) {
  //           const sorted = response.sort((a, b) =>
  //             a.mdsCharacteristicDescription > b.mdsCharacteristicDescription
  //               ? 1
  //               : -1
  //           );
  //           setSpecificMDSChars(sorted);
  //           setIsSpecificMDSCharsLoading(false);
  //         }
  //       })
  //       .finally(() => {
  //         setIsSpecificMDSCharsLoading(false);
  //       });
  //   };

  //   fetchRows();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   id,
  //   getMDSCharacterictics,
  //   dispatch,
  //   isGeneralEquipmentsUpdated,
  //   isSpecificEquipmentsUpdated,
  // ]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getEquipmentStatuses().then((response) => {
        if (isMounted && response) {
          const statuses = getStatusesByUserRole(role, response);
          const mappedStatuses = statuses
            .map((item) => ({
              label: item.characteristicStatusName,
              value: item.characteristicStatusPhk,
            }))
            .filter((item) => item.label);
          setEquipmentStatuses(mappedStatuses);
        }
      });
    };

    fetchRows();

    return () => {
      isMounted = false;
    };
  }, [getEquipmentStatuses, role]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getWalkdownPictures([equipmentTagId])
        .then((response) => {
          if (isMounted && response) {
            setImagesInfo({
              images: response?.[0]?.equipmentImageInfo,
              user: response?.[0]?.user,
            });
          }
        })
        .finally(() => {
          setIsImagesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [equipmentTagId, getWalkdownPictures, isEquipmentPicturesUpdated]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getCostCenterOptions().then((response) => {
        if (isMounted && response) {
          setCostCenterOptions(response);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getCostCenterOptions]);

  const handleClick = (btn) => {
    setActiveButton(btn);
  };

  return (
    <div>
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/work-with-data")}
      >
        Back
      </Button>
      <div className="mt-8 font-fira text-xl not-italic font-bold leading-6">
        {id}
      </div>
      <div className="mt-6">
        <ButtonGroup variant="outlined" aria-label="Equipment details section">
          <Button
            sx={{
              backgroundColor: activeButton === "generalJDE" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("generalJDE")}
            data-testid="generalJDE-btn"
          >
            General JDE
          </Button>
          {/* <Button
            sx={{
              backgroundColor: activeButton === "generalMDS" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("generalMDS")}
            data-testid="generalMDS-btn"
          >
            General MDS
          </Button> */}
          {/* <Button
            sx={{
              backgroundColor:
                activeButton === "class-specific-mds" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("class-specific-mds")}
            data-testid="classSpecificMDS-btn"
          >
            Class-specific MDS
          </Button> */}
          <Button
            sx={{
              backgroundColor: activeButton === "documents" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("documents")}
            data-testid="documents-btn"
          >
            Documents
          </Button>
          <Button
            sx={{
              backgroundColor:
                activeButton === "walkdown-pictures" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("walkdown-pictures")}
            data-testid="walkdown-pictures-btn"
          >
            Walkdown pictures
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-6">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {activeButton === "generalJDE" && (
            <GeneralJDECharacteristics
              loading={isGeneralJDECharsLoading}
              rows={generalJDEChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )}
          {/* {activeButton === "generalMDS" && (
            <GeneralMDSCharacteristics
              loading={isGeneralMDSCharsLoading}
              rows={generalMDSChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )} */}
          {/* {activeButton === "class-specific-mds" && (
            <ClassSpecificMDSCharacteristics
              loading={isSpecificMDSCharsLoading}
              rows={specificMDSChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )} */}
        </LocalizationProvider>
        {activeButton === "documents" && <EquipmentDocuments />}
        {activeButton === "walkdown-pictures" && (
          <EquipmentWalkdownPictures
            images={imagesInfo?.images || []}
            user={imagesInfo?.user}
            loading={isImagesLoading}
          />
        )}
      </div>
    </div>
  );
};

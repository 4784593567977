import { useState, useEffect, useCallback } from "react";
import Iframe from "react-iframe";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import { DCAM, WALKDOWN_APP, JDE_DATA_PROFILE } from "../../constants/monitor";

export const Monitor = () => {
  const [activeButton, setActiveButton] = useState("all_units");
  const [selectedUrl, setSelectedUrl] = useState(DCAM);

  useEffect(() => {
    switch (activeButton) {
      case "all_units":
        setSelectedUrl(DCAM);
        break;
      case "walkdown":
        setSelectedUrl(WALKDOWN_APP);
        break;
      case "data_profile":
        setSelectedUrl(JDE_DATA_PROFILE);
        break;
      default:
        return null;
    }
  }, [activeButton]);

  const renderIframe = useCallback(() => {
    return <Iframe url={selectedUrl} width="1200px" height="600px" />;
  }, [selectedUrl]);

  const handleClick = (btn) => {
    setActiveButton(btn);
  };

  return (
    <div className="min-w-[800px] max-w-[1200px] h-screen">
      <ButtonGroup variant="outlined" aria-label="Power Bi Dashboards">
        <Button
          sx={{
            backgroundColor: activeButton === "all_units" ? "#0066B214" : "",
          }}
          onClick={() => handleClick("all_units")}
        >
          All Units
        </Button>
        <Button
          sx={{
            backgroundColor: activeButton === "walkdown" ? "#0066B214" : "",
          }}
          onClick={() => handleClick("walkdown")}
        >
          Walkdown App
        </Button>
        <Button
          sx={{
            backgroundColor: activeButton === "data_profile" ? "#0066B214" : "",
          }}
          onClick={() => handleClick("data_profile")}
        >
          JDE Data Profile
        </Button>
      </ButtonGroup>
      <div className="mt-6 h-auto w-auto bg-white">{renderIframe()}</div>
    </div>
  );
};

import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUserData } from "../hooks/useUserData";
import { CircularProgress } from "@mui/material";

import {
  setUserRole,
  setIsUserActionsEnabled,
} from "../store/slices/global/userSlice";
import { setColumns } from "../store/slices/work-with-data/columnsSlice";
import { setColumns as mmSetColumns } from "../store/slices/materials-management/columnsSlice";

import { TABLE_COLUMNS } from "../constants/work-with-data";
import { TABLE_COLUMNS as MM_TABLE_COLUMNS } from "../constants/materials-management";
import { USER_ROLE, USER_ROLE_BY_ID } from "../constants/environment";

import { Container, Header, Main } from "./layout";

export const App = () => {
  const dispatch = useDispatch();
  const { getUserRole, loading } = useUserData();

  const setDefaultTableColumns = useCallback(
    (userRole) => {
      if (userRole === USER_ROLE.SME) {
        dispatch(setColumns(TABLE_COLUMNS.SME_USER));
      } else {
        dispatch(setColumns(TABLE_COLUMNS.DEFAULT_USER));
      }
      dispatch(mmSetColumns(MM_TABLE_COLUMNS));
    },
    [dispatch]
  );

  useEffect(() => {
    let isMounted = true;
    const fetchData = () => {
      getUserRole().then((user) => {
        if (isMounted && user) {
          dispatch(setUserRole(USER_ROLE_BY_ID[user.id]));
          setDefaultTableColumns(USER_ROLE_BY_ID[user.id]);
          if (USER_ROLE_BY_ID[user.id] === USER_ROLE.RESTRICTED) {
            dispatch(setIsUserActionsEnabled(false));
          } else {
            dispatch(setIsUserActionsEnabled(true));
          }
        } else {
          dispatch(setUserRole(USER_ROLE.RESTRICTED));
          setDefaultTableColumns(USER_ROLE.RESTRICTED);
        }
      });
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [dispatch, getUserRole, setDefaultTableColumns]);

  return (
    <Container>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <Header />
          <Main />
        </>
      )}
    </Container>
  );
};

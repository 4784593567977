import {
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";

export const TableLoader = ({ colSpan, width }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan} sx={{ minWidth: width }}>
          <div className="flex flex-col items-center justify-center gap-4 w-full min-h-[300px] select-none">
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

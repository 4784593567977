import { Link, useLocation } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import { TabContext } from "@mui/lab";

export const Navbar = ({
  isWorkWithDataEnabled,
  isWalkdownManagementEnabled,
  isMaterialsManagementEnabled,
}) => {
  const { pathname } = useLocation();

  return (
    <TabContext value={pathname}>
      <Box sx={{ width: "100%" }}>
        <Tabs value={pathname} aria-label="page navigation" role="navigation">
          {isWorkWithDataEnabled && (
            <Tab
              LinkComponent={Link}
              label="Work With Data"
              to="/work-with-data"
              value="/work-with-data"
            />
          )}
          {isWalkdownManagementEnabled && (
            <Tab
              LinkComponent={Link}
              label="Walkdown Management"
              to="/walkdown-management"
              value="/walkdown-management"
            />
          )}
          {isMaterialsManagementEnabled && (
            <Tab
              LinkComponent={Link}
              label="Materials Management"
              to="/materials-management"
              value="/materials-management"
            />
          )}
          <Tab
            LinkComponent={Link}
            label="Monitor"
            to="/monitor"
            value="/monitor"
          />
        </Tabs>
      </Box>
    </TabContext>
  );
};

import { Autocomplete, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { ListboxComponent } from "./ListBoxComponent";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

export const VirtualizedAutocomplete = ({
  label,
  options,
  isReset,
  value,
  filterId,
  freeSolo,
  ...rest
}) => {
  return (
    <Autocomplete
      {...rest}
      filterOptions={filterOptions}
      disableListWrap
      ListboxComponent={ListboxComponent}
      options={options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      noOptionsText="No options"
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            "data-testid": `value-input-${filterId}`,
          }}
          variant="outlined"
          label={label}
          fullWidth
        />
      )}
      freeSolo={freeSolo}
      key={isReset}
    />
  );
};

import { TableBody, TableCell, TableRow } from "@mui/material";

import noTagsFound from "../../assets/no-tags-found.svg";

export const NoDataFoundInTable = ({ label, colSpan }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <div className="flex flex-col items-center justify-center gap-4 w-full min-h-[300px] select-none">
            <img src={noTagsFound} alt="not found" width={100} />
            <span className="font-fira text-black/[0.54]">{label}</span>
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

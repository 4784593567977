import { Image } from "antd";
import Skeleton from "@mui/material/Skeleton";

import { getFormattedImageLink } from "../../utils";

export const ImagesGalleryList = ({
  images = [],
  width,
  height,
  renderMask,
}) => {
  return (
    <Image.PreviewGroup>
      {images.map((item) => (
        <Image
          key={item.equipmentImagePk}
          width={width}
          height={height}
          style={{ objectFit: "cover" }}
          src={getFormattedImageLink(item.imageLink)}
          fallback={`https://placehold.co/${width}x${height}?text=Image+Error`}
          preview={{
            mask: renderMask(item),
          }}
          placeholder={
            <Skeleton variant="rectangular" width={width} height={height} />
          }
        />
      ))}
    </Image.PreviewGroup>
  );
};
